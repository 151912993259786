import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { useFormikContext } from 'formik';

import { ISummaryFormSection } from '../../models/summary-form-section';
import { ILabelSummaryFormValues } from '../../models/summary-form.model';

import FormField from '../atoms/form-field';
import FormInput from '../atoms/form-input';

const SummaryFormDeliverySection: React.FC<ISummaryFormSection> = ({
    wrapperClassName,
    gridClassName,
    labelClassName,
    fieldClassName,
    smallFieldClassName = '',
}) => {
    const { t } = useI18next();
    const { errors, touched } = useFormikContext<ILabelSummaryFormValues>();

    return (
        <div className={wrapperClassName}>
            <span className={labelClassName}>{t('label.summary.form.shipment.section')}</span>
            <div className={gridClassName}>
                <FormField
                    className={fieldClassName}
                    labelText={t('label.summary.form.shipment.name.label')}
                    id={'shipmentName'}
                    hintText={
                        errors.shipmentName && touched.shipmentName ? errors.shipmentName : null
                    }
                    errorStyle={!!(errors.shipmentName && touched.shipmentName)}
                >
                    <FormInput
                        id={'shipmentName'}
                        name={'shipmentName'}
                        placeholder={t('label.summary.form.shipment.name.placeholder')}
                        errorStyle={!!(errors.shipmentName && touched.shipmentName)}
                    />
                </FormField>
                <FormField
                    className={fieldClassName}
                    labelText={t('label.summary.form.shipment.surname.label')}
                    id={'shipmentSurname'}
                    hintText={
                        errors.shipmentSurname && touched.shipmentSurname
                            ? errors.shipmentSurname
                            : null
                    }
                    errorStyle={!!(errors.shipmentSurname && touched.shipmentSurname)}
                >
                    <FormInput
                        id={'shipmentSurname'}
                        name={'shipmentSurname'}
                        placeholder={t('label.summary.form.shipment.surname.placeholder')}
                        errorStyle={!!(errors.shipmentSurname && touched.shipmentSurname)}
                    />
                </FormField>
                <FormField
                    className={fieldClassName}
                    labelText={t('label.summary.form.shipment.city.label')}
                    id={'shipmentCity'}
                    hintText={
                        errors.shipmentCity && touched.shipmentCity ? errors.shipmentCity : null
                    }
                    errorStyle={!!(errors.shipmentCity && touched.shipmentCity)}
                >
                    <FormInput
                        id={'shipmentCity'}
                        name={'shipmentCity'}
                        placeholder={t('label.summary.form.shipment.city.placeholder')}
                        errorStyle={!!(errors.shipmentCity && touched.shipmentCity)}
                    />
                </FormField>
                <FormField
                    className={`${fieldClassName} ${smallFieldClassName}`}
                    labelText={t('label.summary.form.shipment.postcode.label')}
                    id={'shipmentPostcode'}
                    hintText={
                        errors.shipmentPostcode && touched.shipmentPostcode
                            ? errors.shipmentPostcode
                            : null
                    }
                    errorStyle={!!(errors.shipmentPostcode && touched.shipmentPostcode)}
                >
                    <FormInput
                        id={'shipmentPostcode'}
                        name={'shipmentPostcode'}
                        placeholder={t('label.summary.form.shipment.postcode.placeholder')}
                        errorStyle={!!(errors.shipmentPostcode && touched.shipmentPostcode)}
                    />
                </FormField>
                <FormField
                    className={`${fieldClassName} ${smallFieldClassName}`}
                    labelText={t('label.summary.form.shipment.phone.label')}
                    id={'shipmentPhone'}
                    hintText={
                        errors.shipmentPhone && touched.shipmentPhone ? errors.shipmentPhone : null
                    }
                    errorStyle={!!(errors.shipmentPhone && touched.shipmentPhone)}
                >
                    <FormInput
                        id={'shipmentPhone'}
                        name={'shipmentPhone'}
                        placeholder={t('label.summary.form.shipment.phone.placeholder')}
                        errorStyle={!!(errors.shipmentPhone && touched.shipmentPhone)}
                    />
                </FormField>
                <FormField
                    className={`${fieldClassName}`}
                    labelText={t('label.summary.form.shipment.street.label')}
                    id={'shipmentStreet'}
                    hintText={
                        errors.shipmentStreet && touched.shipmentStreet
                            ? errors.shipmentStreet
                            : null
                    }
                    errorStyle={!!(errors.shipmentStreet && touched.shipmentStreet)}
                >
                    <FormInput
                        id={'shipmentStreet'}
                        name={'shipmentStreet'}
                        placeholder={t('label.summary.form.shipment.street.placeholder')}
                        errorStyle={!!(errors.shipmentStreet && touched.shipmentStreet)}
                    />
                </FormField>
                <FormField
                    className={`${fieldClassName} ${smallFieldClassName}`}
                    labelText={t('label.summary.form.shipment.building.number.label')}
                    id={'shipmentBuildingNumber'}
                    hintText={
                        errors.shipmentBuildingNumber && touched.shipmentBuildingNumber
                            ? errors.shipmentBuildingNumber
                            : null
                    }
                    errorStyle={!!(errors.shipmentBuildingNumber && touched.shipmentBuildingNumber)}
                >
                    <FormInput
                        id={'shipmentBuildingNumber'}
                        name={'shipmentBuildingNumber'}
                        placeholder={t('label.summary.form.shipment.building.number.placeholder')}
                        errorStyle={
                            !!(errors.shipmentBuildingNumber && touched.shipmentBuildingNumber)
                        }
                    />
                </FormField>
                <FormField
                    className={`${fieldClassName} ${smallFieldClassName}`}
                    labelText={t('label.summary.form.shipment.local.number.label')}
                    id={'shipmentLocalNumber'}
                    hintText={
                        errors.shipmentLocalNumber && touched.shipmentLocalNumber
                            ? errors.shipmentLocalNumber
                            : null
                    }
                    errorStyle={!!(errors.shipmentLocalNumber && touched.shipmentLocalNumber)}
                >
                    <FormInput
                        id={'shipmentLocalNumber'}
                        name={'shipmentLocalNumber'}
                        placeholder={t('label.summary.form.shipment.local.number.placeholder')}
                        errorStyle={!!(errors.shipmentLocalNumber && touched.shipmentLocalNumber)}
                    />
                </FormField>
            </div>
        </div>
    );
};

export default SummaryFormDeliverySection;
